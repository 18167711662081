import { useI18n } from 'vue-i18n';
import { useAccountStore } from '@/store/account';
import { useFrameStore } from '@/store/frame';
import { useRouter } from 'vue-router';

import { getListIdByShorturl } from '@/api/api';

export default function () {
  const { setSession } = useAccountStore();
  const { updateFrameData, setIsRedirecting } = useFrameStore();
  const router = useRouter();
  // Parse URL params
  const urlParams = new URLSearchParams(window.location.search);
  // const newLocale = urlParams.get('locale');
  // if (newLocale) {
  //   const { locale } = useI18n({ useScope: 'global' });
  //   locale.value = newLocale;
  // }

  const session = urlParams.get('session');
  // if (!session) {
  //     const s = document.cookie.split(';').find((cookie) => cookie.trim().startsWith('benders_logged_in='))
  //     if (s) {
  //         session = s.split('=')[1]
  //     }
  // }
  if (session) {
    setSession(session);
  }

  const shorturl = urlParams.get('shorturl');
  if (shorturl) {
    setIsRedirecting(true);
    getListIdByShorturl(shorturl)
      .then((id) => {
        router.push({ name: 'project', params: { id } }).then(() => {
          setIsRedirecting(false);
        });
      })
      .catch(() => {
        router.push({ name: '404' }).then(() => {
          setIsRedirecting(false);
        });
      });
  }

  window.addEventListener('message', (event) => {
    const data = event.data;
    if (typeof data !== 'object' || data === null) {
      return;
    }
    switch (data.type) {
      case 'bendersReady':
        window.parent.postMessage({ type: 'bendersInit' }, '*');
        break;
      case 'bendersInit':
        updateFrameData(data.data);
        if (data.data?.session) {
          setSession(data.data.session);
        }
        break;
      // case "bendersLoading":
      //   this.$store.dispatch("updateFrameData", {
      //     ...this.$store.state.frameData,
      //     loading: data.data,
      //   });
      //   break;
    }
  });

  // remove session from URL (If navigation breaks, it's probably because of this)
  setTimeout(() => {
    const url = new URL(window.location.href);
    url.searchParams.delete('session'); // Remove the 'session' parameter
    window.history.replaceState({}, '', url.toString()); // Update the URL without reloading the page
  }, 0);
  // router.replace({
  //   path: url.pathname,
  //   query: query,
  // });
}
