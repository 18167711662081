import { AccessoryColor } from '@/enums';
import { RoofArticle, TileFamily, Underlagstak, MarketStr } from './common';
import { RoofTile } from './rooftiles/rooftile';
import { addResults, Category, removeZeroQtyRows, type ResultRow } from './result';
import type { Roof } from './roof';
import { SAFECalc } from './safecalc';

/** Class representing the PW configuration of a roof. Used to calculate all PW articles to add to summary */
export class PWCalc extends SAFECalc {

  public fasteningEyeArtNo = '138690';

  public constructor(roof: Roof) {
    super(roof);

    this.roof = roof;
    this.roof.safe = this;

    // PW specifika mätvärden
    this.snowProtectionLength = 2.33;
    this.roofRailLength = 2.3;
  }

  public getSnowSafetyArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '139620';
      case AccessoryColor.BrickRed:
        return '139624';
      case AccessoryColor.GalvanizedGreySilver:
        return '139690';
    }
    return '';
  }

  public getConsoleArtNo(): string {
    let color = '90';
    if      (this.selColor === AccessoryColor.Black)                color = '20';
    else if (this.selColor === AccessoryColor.BrickRed)             color = '24';
    else if (this.selColor === AccessoryColor.GalvanizedGreySilver) color = '90';

    switch (this.roof.selection.tileFamily.family) {
      case TileFamily.Palema:
      case TileFamily.Exklusiv:
      case TileFamily.HansaFalsatLertegel:
      case TileFamily.HansaFalsatLertegelRakFramkant:
      case TileFamily.TvillingLertegelRakFramkant:
      case TileFamily.TvillingFalsatLertegel:
        if (this.roof.underlagstak === Underlagstak.RåspontMedPapp)
          return '1348' + color;
        else
          return '1376' + color;
      case TileFamily.Carisma:
      case TileFamily.PianoFalsatLertegel:
        return 'C1348' + color;
        // Borde bara användas för råspont men carisma och piano borde inte vara valbara alls för andra underlagstak
      case TileFamily.Strängpressat2kupLertegel:
      // case TileFamily.Sandby:
        if (color === '20') color = '90'; // ugliest fallback ever, L134820 doesn't exist
        return 'L1348' + color;
        // Borde bara användas för råspont men höganäs borde inte vara valbar för andra underlagstak
    }
    return '';
  }

  public getSnowSafetyLockingSetArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '139720';
      case AccessoryColor.BrickRed:
        return '139724';
      case AccessoryColor.GalvanizedGreySilver:
        return '139790';
    }
    return '';
  }

  public getRoofRailLockingSetArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '130820';
      case AccessoryColor.BrickRed:
        return '130824';
      case AccessoryColor.GalvanizedGreySilver:
        return '130890';
    }
    return '';
  }

  public getFootPlatScrewsArtNo(): string {
    return '136090';
  }

  public getRoofBridgeShortArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '137720';
      case AccessoryColor.BrickRed:
        return '137724';
      case AccessoryColor.GalvanizedGreySilver:
        return '137790';
    }
    return '';
  }

  public getRoofBridgeArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '137820';
      case AccessoryColor.BrickRed:
        return '137824';
      case AccessoryColor.GalvanizedGreySilver:
        return '137890';
    }
    return '';
  }

  public getÖverdelArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '136920';
      case AccessoryColor.BrickRed:
        return '136924';
      case AccessoryColor.GalvanizedGreySilver:
        return '136990';
    }
    return '';
  }

  public getRoofRailArtNo(): string {
    const roofRailBase = '1300';
    switch (this.selColor) {
      case AccessoryColor.Black:
        return `${roofRailBase}20`;
      case AccessoryColor.BrickRed:
        return `${roofRailBase}24`;
      case AccessoryColor.GalvanizedGreySilver:
        return `${roofRailBase}90`;
    }
    return '';
  }

  public getStagArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '134320';
      case AccessoryColor.BrickRed:
        return '134324';
      case AccessoryColor.GalvanizedGreySilver:
        return '134390';
    }
    return '';
  }

  public getRoofHatchArtNo(): string {
    switch (this.selColor) {
      case AccessoryColor.Black:
        return '135120';
      case AccessoryColor.BrickRed:
        return '135124';
      case AccessoryColor.GalvanizedGreySilver:
        return '135190';
    }
    return '';
  }

  public calculateConsoles(): number {
    const distanceConsole = this.calculateDistanceConsole();
    const rows = this.calculateRowsSnowProtection();

    let amountPerRow = 0;
    for (const len of this.snowSafetyLengths) {
      const wholeLengths = Math.ceil((+len.toFixed(2) - 0.5) / this.snowProtectionLength) * this.snowProtectionLength;
      amountPerRow += Math.ceil(+wholeLengths.toFixed(2) / distanceConsole - 0.3) + 1;
    }

    const amount = amountPerRow * rows;
    this.calcResults.snowSafetyConsoles = amount;

    return amount;
  }

  public getSkarvBultar(): number {
    let bultar = 0;

    bultar += Math.max(Math.ceil(Math.round(this.calcResults.snowSafety - 1) / 2), 0);
    bultar += Math.max(Math.ceil(Math.round(this.calcResults.roofBridgeLong + this.calcResults.roofBridgeShort - 1) / 2 + Math.round(this.calcResults.roofBridgeConsoles)), 0);

    return bultar;
  }

  public getFootPlatScrews(): number {
    const consoleArtNo = this.getConsoleArtNo();

    let screwsPerConsole = 1; // For 1348xx, C1348xx, L1348xx
    if (consoleArtNo.startsWith('C1348')) screwsPerConsole = 0;
    if (consoleArtNo.startsWith('1376')) screwsPerConsole = 0.5;

    return screwsPerConsole * (this.calcResults.snowSafetyConsoles + this.calcResults.roofBridgeConsoles + this.calcResults.roofRailConsoles
                              + this.roofRailsAmount * 4);
  }

  public calculateRoofBridge(): ResultRow[] {
    // This gets messy since the short art isn't exactly half of the long one. 
    const lengthLong = 2.31;
    const lengthShort = lengthLong / 2; // 1.19;
    let amountLong = 0;
    let amountShort = 0;

    this.roofBridgeLengths.forEach((len) => {
      // BK-245: Vi kräver att resten från alla jämt beräknade korta takbryggor överstiger 30cm innan det beräknas en extra
      const shortPieces = Math.ceil(Math.max(0.01, len - 0.3) / lengthShort);
      amountLong += Math.floor(shortPieces / 2);
      amountShort += Math.ceil(shortPieces % 2);
    });
    this.calcResults.roofBridgeLong = amountLong;
    this.calcResults.roofBridgeShort = amountShort;

    return [
      { art: RoofArticle.None, artnr: this.getRoofBridgeArtNo(), total: amountLong, category: Category.Taksäkerhet, origins: {} },
      { art: RoofArticle.None, artnr: this.getRoofBridgeShortArtNo(), total: amountShort, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public calculateRoofBridgeConsoles(): ResultRow[] {
    const lengthLong = 2.31;
    const lengthShort = lengthLong / 2; // 1.19;
    let amount = 0;

    this.roofBridgeLengths.forEach((len) => {
      const shortPieces = Math.ceil(Math.max(0.01, len - 0.3) / lengthShort);
      const amountLong = Math.floor(shortPieces / 2);
      const amountShort = Math.ceil(shortPieces % 2);

      const length = amountLong * lengthLong + amountShort * lengthShort;
      amount += Math.ceil(Number((length / this.roofMultiplier()).toFixed(1))) + 1;
    });

    this.calcResults.roofBridgeConsoles = amount;

    const artno = this.getConsoleArtNo();
    return [
      { art: RoofArticle.None, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public calculateRoofRailConsoles(): ResultRow[] {
    const amount = this.amountOfAccessories(this.hatchSafetyLengths, this.roofRailLength, 0, this.roofMultiplier());
    this.calcResults.roofRailConsoles = amount;

    const artno = this.getConsoleArtNo();
    return [
      { art: RoofArticle.None, artnr: artno, total: amount, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public async calculateExtraConsoleArticles(consoles: number): Promise<ResultRow[]> {
    if (this.roof.selection.tileFamily != RoofTile.Carisma) return [];

    const art = RoofArticle.InfästningsPannaEuro
    const artno = await this.roof.selection.getArticleNumber(art, false, true);
    if (!artno) return [];

    return [
      { art: art, artnr: artno, total: consoles, category: Category.Taksäkerhet, origins: {} },
    ] as ResultRow[];
  }

  public async calculate(): Promise<ResultRow[]> {
    if (!this.roof.mainPart) throw Error('Main roofpart not set');
    if (!this.selColor) throw Error('Color not set');
    if (this.useSAFE && this.snowSafetyLengths?.length && !this.snowZone) throw Error('Snow-zone not set');

    let results: ResultRow[] = [];

    // Add roof-safety-system independent articles
    this.addArticleRowByArtnr(results, this.battenStepArtNo, this.selBattenSteps, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.mountingRailArtNo, this.selMountingRail, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.slipProtectionArtNo, this.selSlipProtection, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.roofHatchArtNo, this.selRoofHatch, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.safetyHookArtNo, this.selSafetyHook, Category.Taksäkerhet);
    this.addArticleRowByArtnr(results, this.snowHookArtNo, this.selSnowHook, Category.Taksäkerhet);
    if (this.selSnowSlideProtection) {
      this.addArticleRowByArtnr(results, this.snowSlideObstacleArtNo, this.calculateSnowSlideObstacles(), Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, this.snowSlideObstacleKonsolArtNo, this.calculateSnowSlideObstacleConsoles(), Category.Taksäkerhet);
    }

    // BENT-218: In SAN when Carisma is selected, ignore the ice stopper choice and calculate it to 2xSnow slides instead
    if (this.roof.market == MarketStr.Norge && [RoofTile.Carisma, RoofTile.Piano].includes(this.roof.selection.tileFamily)) {
      const calcedSnowSlides = results.find(r => r.artnr == this.snowSlideObstacleArtNo)?.total ?? 0;
      this.addArticleRowByArtnr(results, this.iceStopperArtNo,
                                2 * calcedSnowSlides, Category.Taksäkerhet);
    }else {
      this.addArticleRowByArtnr(results, this.iceStopperArtNo, this.selIceStopper, Category.Taksäkerhet);
    }

    results = addResults(results, await this.calculateTrackTiles());

    if (this.useSAFE) {
      const conArtNo = this.getConsoleArtNo();

      // Snörasskydd
      this.addArticleRowByArtnr(results, this.getSnowSafetyArtNo(), this.calculateSnowSafety(), Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, conArtNo, this.calculateConsoles(), Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, this.getSnowSafetyLockingSetArtNo(), this.calcResults.snowSafetyConsoles, Category.Taksäkerhet);

      // Takbrygga
      results = addResults(results, this.calculateRoofBridge());
      results = addResults(results, this.calculateRoofBridgeConsoles());
      this.addArticleRowByArtnr(results, this.getÖverdelArtNo(), this.calcResults.roofBridgeConsoles, Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, this.getStagArtNo(), this.roofBridgeLengths.length, Category.Taksäkerhet);

      // Nockräcke
      this.addArticleRowByArtnr(results, this.getRoofRailArtNo(), this.calculateRoofRail(), Category.Taksäkerhet);
      results = addResults(results, this.calculateRoofRailConsoles());
      this.addArticleRowByArtnr(results, this.getRoofRailLockingSetArtNo(), this.hatchSafetyLengths.length, Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, '130190', Math.ceil(this.calcResults.roofRailSkarvar / 10), Category.Taksäkerhet);

      // Skyddsräcken
      this.addArticleRowByArtnr(results, this.getRoofHatchArtNo(), this.roofRailsAmount, Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, conArtNo, 4 * this.roofRailsAmount, Category.Taksäkerhet);

      results = addResults(results, await this.calculateExtraConsoleArticles(results.find(r => r.artnr.startsWith(conArtNo))?.total ?? 0));

      this.addArticleRowByArtnr(results, this.getFootPlatScrewsArtNo(), this.getFootPlatScrews(), Category.Taksäkerhet);
      this.addArticleRowByArtnr(results, '132900', this.getSkarvBultar(), Category.Taksäkerhet);

      this.addArticleRowByArtnr(results, this.fasteningEyeArtNo, this.fasteningEyeAmount, Category.Taksäkerhet);
    }

    // Remove empty result rows
    removeZeroQtyRows(results);

    // Multiply by number of roofs
    for (const row of results) row.total *= this.roof.amount;

    return results;
  }
}
